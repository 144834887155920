import React from 'react';
import './rraid.scss';

const RRaid: React.FC = () => {
    return (
        <h1 className='center'>RequestRAID: Coming soon to a theater near you!</h1>
    );
};

export default RRaid;
