// App.tsx
import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import RRaid from './components/rraid/rraid';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="landing-page">
            <header>
              <h1>Welcome to Deepgram's Help Center</h1>
              <p>Navigate to the documentation, FAQs, or support center</p>
            </header>
            <main>
              <div className="box-container">
                <a href="https://developers.deepgram.com/" className="box box1">
                  <h2>Documentation</h2>
                  <p>Explore the Deepgram developer documentation</p>
                </a>
                <a href="https://deepgram.gitbook.io/help-center" className="box box3">
                  <h2>Help Center</h2>
                  <p>Find answers to frequently asked questions</p>
                </a>
                <a href="https://deepgram.zendesk.com/hc/en-us/requests" className="box box2">
                  <h2>Support Tickets</h2>
                  <p>Track support ticket question status</p>
                </a>
                {/*
                <Link to="/rraid" className="box box4">
                  <h2>Experimental Features</h2>
                  <p>Debug your requests in realtime</p>
                </Link>
                */}
              </div>
            </main>
            <footer>
              <p>&copy; 2024 Deepgram. All rights reserved.</p>
            </footer>
          </div>
        } />
        <Route path="/rraid" element={<RRaid />} />
      </Routes>
    </Router>
  );
};

export default App;
